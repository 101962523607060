import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "filename", "info", "dropzone", "spinner", "icon"];

  connect() {
    this.dropzoneTarget.addEventListener(
      "dragover",
      this.handleDragOver.bind(this)
    );
    this.dropzoneTarget.addEventListener("drop", this.handleDrop.bind(this));
  }

  updateFileDetails() {
    const file = this.inputTarget.files[0];

    if (file) {
      const filename = file.name;
      const filesize = (file.size / 1024).toFixed(2) + " KB";
      this.filenameTarget.classList.remove("hidden");
      this.filenameTarget.classList.add("flex");
      this.infoTarget.textContent = `${filename} (${filesize})`;
    } else {
      this.filenameTarget.classList.remove("flex");
      this.filenameTarget.classList.add("hidden");
      this.infoTarget.textContent = "No file chosen";
    }
  }

  handleDragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy"; // Indicate a copy action
  }

  handleDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.inputTarget.files = files;
      this.updateFileDetails();
    }
  }

  showSpinner() {
    this.spinnerTarget.classList.remove("hidden");
    this.spinnerTarget.classList.add("inline-flex");
    this.iconTarget.classList.add("hidden");
  }
}
