import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];

  switch(event) {
    event.preventDefault();
    console.log("Tab switch triggered");
    const targetTab = event.currentTarget;
    const targetContentId = targetTab.getAttribute("href").replace("#", "");

    // Deactivate all tabs and contents
    this.tabTargets.forEach((tab) => tab.classList.remove("active"));
    this.contentTargets.forEach((content) => content.classList.add("hidden"));

    // Activate the clicked tab and corresponding content
    targetTab.classList.add("active");
    const targetContent = this.contentTargets.find(
      (content) => content.id === targetContentId
    );
    if (targetContent) {
      targetContent.classList.remove("hidden");
    }
  }
}
