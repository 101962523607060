import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropdown", "form", "content", "toggle"];

  connect() {
    document.addEventListener("click", this.handleClickOutside.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.handleClickOutside.bind(this));
  }

  toggleDropdown() {
    this.dropdownTarget.classList.toggle("hidden");
  }

  showEditForm() {
    this.formTarget.classList.remove("hidden");
    this.contentTargets.forEach((content) => content.classList.add("hidden"));

    // Hide the dropdown if it’s visible
    if (!this.dropdownTarget.classList.contains("hidden")) {
      this.dropdownTarget.classList.add("hidden");
    }
  }

  hideEditForm() {
    this.formTarget.classList.add("hidden");
    this.contentTargets.forEach((content) =>
      content.classList.remove("hidden")
    );
  }

  handleClickOutside(event) {
    if (
      this.hasDropdownTarget &&
      !this.dropdownTarget.contains(event.target) && // Click is not inside the dropdown
      !this.toggleTarget.contains(event.target) // Click is not on the toggle button
    ) {
      this.dropdownTarget.classList.add("hidden");
    }
  }
}
