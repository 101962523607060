import { Modal } from "tailwindcss-stimulus-components";

export default class extends Modal {
  connect() {
    super.connect();
    this.dialogTarget.showModal();

    this.boundHandleBeforeTurboVisit = this.handleBeforeTurboVisit.bind(this);

    window.addEventListener(
      "turbo:before-visit",
      this.boundHandleBeforeTurboVisit
    );
  }

  disconnect() {
    super.disconnect();

    window.removeEventListener(
      "turbo:before-visit",
      this.boundHandleBeforeTurboVisit
    );
  }

  async close() {
    this.dialogTarget.setAttribute("closing", "");

    return Promise.all(
      this.dialogTarget.getAnimations().map((animation) => animation.finished)
    ).then(() => {
      const modal = document.getElementById("modal");
      modal.removeAttribute("src");
      modal.innerHTML = "";
    });
  }

  handleBeforeTurboVisit(event) {
    event.preventDefault();

    this.close().then(() => {
      Turbo.visit(event.detail.url);
    });
  }
}
