import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "backdrop"];
  static values = {
    open: Boolean,
  };
  connect() {
    if (this.openValue) this.open();
    this.boundBeforeCache = this.beforeCache.bind(this);
    document.addEventListener("turbo:before-cache", this.boundBeforeCache);
  }
  disconnect() {
    document.removeEventListener("turbo:before-cache", this.boundBeforeCache);
  }
  open() {
    this.modalTarget.classList.remove("hidden"); // Make modal visible
    this.backdropTarget.classList.remove("hidden"); // Make backdrop visible
    // Add animation classes if needed
    this.modalTarget.classList.add("animated", "fadeIn");
  }
  close() {
    this.modalTarget.classList.add("animated", "fadeOut");
    // Wait for animations to finish before hiding
    Promise.all(
      [this.modalTarget, this.backdropTarget].flatMap((element) =>
        element.getAnimations().map((animation) => animation.finished)
      )
    ).then(() => {
      this.modalTarget.classList.add("hidden");
      this.backdropTarget.classList.add("hidden");
    });
  }
  backdropClose(event) {
    if (event.target === this.backdropTarget) this.close(); // Close if clicking outside modal
  }
  beforeCache() {
    this.close();
  }
}
