import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = [
    "owner",
    "collaborators",
    "taskOwner",
    "taskOwnerName",
    "taskOwnerId",
  ];
  static values = { url: String };

  connect() {
    this.initOwnerSelect();
    this.initCollaboratorsSelect();

    this.ownerChangeListener = () => {
      this.updateCollaborators();
      this.updateTaskOwnerId();
    };

    this.collaboratorsChangeListener = () => {
      this.updateTaskOwnerId();
    };

    this.ownerTarget.addEventListener("change", this.ownerChangeListener);
    this.collaboratorsTarget.addEventListener(
      "change",
      this.collaboratorsChangeListener
    );
  }

  taskOwnerTargetConnected(targetElement) {
    this.initTaskOwnerDropdown(targetElement);
    this.updateTaskOwnerDropdownOptions(targetElement);
  }

  taskOwnerTargetDisconnected(element) {
    console.log("taskOwnerTargetDisconnected");
    if (element.tomselect) {
      element.tomselect.destroy();
    }
  }

  disconnect() {
    this.destroyTomSelects();
    this.ownerTarget.removeEventListener("change", this.ownerChangeListener);
    this.collaboratorsTarget.removeEventListener(
      "change",
      this.collaboratorsChangeListener
    );
  }

  destroyTomSelects() {
    if (this.ownerSelect) {
      this.ownerSelect.destroy();
    }
    if (this.collaboratorsMultiSelect) {
      this.collaboratorsMultiSelect.destroy();
    }
    this.taskOwnerTargets.forEach((targetElement) => {
      if (targetElement.tomselect) {
        targetElement.tomselect.destroy();
      }
    });
  }

  initOwnerSelect() {
    if (!this.ownerSelect) {
      this.ownerSelect = new TomSelect(this.ownerTarget, {
        placeholder: "Select owner",
        hidePlaceholder: true,
        maxItems: 1,
        hideSelected: true,
        create: false,
        plugins: ["dropdown_input"],
        render: {
          option: (data, escape) =>
            `<div><span class="block">${escape(data.text)}</span></div>`,
          item: (data, escape) => `<div>${escape(data.text)}</div>`,
        },
        onChange: () => {
          this.updateCollaborators();
        },
      });
    }
  }

  initCollaboratorsSelect() {
    const options = {
      placeholder: "Select collaborators",
      hidePlaceholder: true,
      preload: true,
      create: false,
      plugins: {
        dropdown_input: {},
        remove_button: { title: "Remove" },
      },
      items: this.getSelectedCollaborators(),
      options: this.getOptionsExcludingOwner(),
    };
    this.collaboratorsMultiSelect = new TomSelect(
      this.collaboratorsTarget,
      options
    );
  }

  updateCollaborators() {
    this.collaboratorsMultiSelect.clear();
    this.collaboratorsMultiSelect.clearOptions();
    this.getOptionsExcludingOwner().forEach((option) =>
      this.collaboratorsMultiSelect.addOption(option)
    );
  }

  getOptionsExcludingOwner() {
    return Array.from(this.collaboratorsTarget.options)
      .filter((option) => option.value !== this.ownerTarget.value)
      .map((option) => ({ value: option.value, text: option.text }));
  }

  getSelectedCollaborators() {
    return Array.from(this.collaboratorsTarget.selectedOptions).map(
      (option) => option.value
    );
  }

  initTaskOwnerDropdown(targetElement) {
    if (!targetElement.tomselect) {
      new TomSelect(targetElement, {
        placeholder: "Select owner",
        hidePlaceholder: true,
        hideSelected: true,
        maxItems: 1,
        create: false,
        plugins: { dropdown_input: {}, remove_button: { title: "Remove" } },
        render: {
          option: (data, escape) =>
            `<div><span class="block">${escape(data.text)}</span></div>`,
          item: (data, escape) => `<div>${escape(data.text)}</div>`,
        },
      });
    }
  }

  updateTaskOwnerDropdownOptions(targetElement) {
    const selectedCollaborators = Array.from(
      this.collaboratorsTarget.selectedOptions
    ).map((option) => option.value);

    const taskOwnerOptions = Array.from(targetElement.options)
      .filter(
        (option) =>
          option.value === this.ownerTarget.value ||
          selectedCollaborators.includes(option.value) ||
          option.value == ""
      )
      .map((option) => ({ value: option.value, text: option.text }));

    const currentSelectedValue = targetElement.tomselect.getValue();
    if (
      !taskOwnerOptions.some((option) => option.value === currentSelectedValue)
    ) {
      targetElement.tomselect.clear();
      targetElement.tomselect.setValue("");
    }
    targetElement.tomselect.clearOptions();
    targetElement.tomselect.addOptions(taskOwnerOptions);
  }

  updateTaskOwnerId() {
    const programOwnerId = this.ownerTarget.value;
    const collaboratorIds = Array.from(
      this.collaboratorsTarget.selectedOptions
    ).map((option) => option.value);

    const allSelectedOptions = Array.from(this.ownerTarget.options).concat(
      Array.from(this.collaboratorsTarget.options)
    );

    this.taskOwnerIdTargets.forEach((idField, index) => {
      const currentTaskOwnerId = idField.value;

      // Update ID field
      if (
        currentTaskOwnerId === programOwnerId ||
        collaboratorIds.includes(currentTaskOwnerId)
      ) {
        idField.value = currentTaskOwnerId;
      } else {
        idField.value = "";
      }

      // Update name div
      const selectedOption = allSelectedOptions.find(
        (option) => option.value === idField.value
      );
      const taskOwnerName = selectedOption?.textContent.trim() || "None";
      const nameDiv = this.taskOwnerNameTargets[index];
      if (nameDiv) {
        nameDiv.textContent = taskOwnerName;
      }
    });
  }
}
