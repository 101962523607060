// app/javascript/controllers/team_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["member"];

  setActive(event) {
    this.memberTargets.forEach((member) => {
      member.classList.remove("bg-indigo-50");
    });

    event.currentTarget.classList.add("bg-indigo-50");
  }
}
