import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["positionField"];

  initialize() {
    this.initialActiveTasks = this.activeTasks().length;
  }

  connect() {
    this.initializeSortable();
    window.addEventListener("task:deleted", this.updatePositions.bind(this));
  }

  disconnect() {
    this.sortable.destroy();
    window.removeEventListener("task:deleted", this.updatePositions.bind(this));
  }

  // Initializes Sortable
  initializeSortable() {
    this.sortable = new Sortable(this.element, {
      onEnd: this.end.bind(this),
    });
  }

  // Updates positions after sorting
  end() {
    this.updatePositions();
  }

  // Updates the positions for all tasks, excluding hidden or destroyed tasks
  updatePositions() {
    const positionFields = this.activeTasks();
    const totalTasks = positionFields.length;
    positionFields.forEach((positionField, index) => {
      positionField.value = totalTasks - index; // Position is highest for last created
    });
  }

  // Returns position fields that are not hidden or marked for destruction
  activeTasks() {
    return this.positionFieldTargets.filter((positionField) => {
      const parentElement = positionField.closest(".task-container");
      const isArchived =
        parentElement.querySelector('[data-task-target="archivedField"]')
          .value === "true";
      const isDestroyed =
        parentElement.querySelector('[data-task-target="destroyField"]')
          .value === "true";
      return !isArchived && !isDestroyed;
    });
  }

  // Checks if the number of positionFields has changed and updates positions if necessary
  positionFieldTargetConnected(event) {
    const currentActiveTasks = this.activeTasks().length;
    if (currentActiveTasks !== this.initialActiveTasks) {
      this.updatePositions();
      this.initialActiveTasks = currentActiveTasks;
    }
  }
}
