import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tagIds"];

  connect() {
    let tagIds = this.tagIdsTarget.value
      ? this.tagIdsTarget.value.split(",")
      : [];

    tagIds.forEach((tagId) => {
      let tagElement = document.querySelector(`[data-tag-id="${tagId}"]`);
      if (tagElement) {
        tagElement.classList.add("bg-blue-500");
      }
    });
  }

  toggleBackground(event) {
    let tagId = event.target.dataset.tagId;
    let tagIds = this.tagIdsTarget.value
      .split(",")
      .filter((id) => id.trim() !== "");
    if (tagIds.includes(tagId)) {
      let index = tagIds.indexOf(tagId);
      if (index > -1) {
        tagIds.splice(index, 1);
      }
    } else {
      tagIds.push(tagId);
      tagIds.sort();
    }
    this.tagIdsTarget.value = tagIds.join(",");
    event.target.classList.toggle("bg-blue-500");
  }
}
