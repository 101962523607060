import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input", "clearButton", "dropdown"];

  initialize() {
    this.handleDropdownClickOutside =
      this.handleDropdownClickOutside.bind(this);
  }

  connect() {
    this.searchType = this.element.dataset.type;

    this.handleInput = this.debounce(() => {
      if (this.inputTarget.value.trim() === "") {
        this.clear();
      } else {
        this.toggleClearButtonVisibility();
        if (this.searchType === "program") {
          this.showDropdown();
        }
        this.element.requestSubmit();
      }
    }, 300);

    this.inputTarget.addEventListener("input", this.handleInput);

    this.inputTarget.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        if (
          this.searchType === "all_capabilities" ||
          this.searchType === "category_capabilities"
        ) {
          return; // Disable Enter key for category or capability search types
        }
        event.preventDefault();
        this.performFullSearch();
      }
    });

    document.addEventListener(
      "click",
      this.handleDropdownClickOutside.bind(this)
    );
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.handleInput);
    document.removeEventListener(
      "click",
      this.handleDropdownClickOutside.bind(this)
    );
  }

  debounce(func, delay) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  }

  async performFullSearch() {
    this.hideDropdown();
    const formData = new FormData(this.element);
    const queryParams = new URLSearchParams(formData).toString();

    try {
      const response = await fetch(`/search?${queryParams}`, {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      });

      if (response.ok) {
        const streamMessage = await response.text();
        Turbo.renderStreamMessage(streamMessage);
      } else {
        console.error("Failed to fetch search results");
      }
    } catch (error) {
      console.error("Error submitting full search request:", error);
    }
  }

  clear() {
    this.inputTarget.value = "";
    this.toggleClearButtonVisibility();

    if (this.searchType === "program") {
      this.hideDropdown();
    } else if (this.searchType === "all_capabilities") {
      this.resetAllCapabilitiesSearch();
    } else if (this.searchType === "category_capabilities") {
      this.element.requestSubmit();
    }
  }

  resetAllCapabilitiesSearch() {
    const backButton = document.getElementById("reset-search-button");
    if (backButton) backButton.click();
  }

  toggleClearButtonVisibility() {
    this.clearButtonTarget.classList.toggle(
      "hidden",
      this.inputTarget.value === ""
    );
  }

  showDropdown() {
    if (this.hasDropdownTarget) {
      this.dropdownTarget.classList.remove("hidden");
    }
  }

  hideDropdown() {
    if (this.hasDropdownTarget) {
      this.dropdownTarget.classList.add("hidden");
    }
  }

  handleDropdownClickOutside(event) {
    if (
      this.searchType === "all_capabilities" ||
      this.searchType === "category_capabilities"
    )
      return; // Skip dropdown handling for capability search

    if (!this.element.contains(event.target)) {
      this.hideDropdown();
    } else if (this.inputTarget.contains(event.target)) {
      this.showDropdown();
    }
  }
}
