import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["snippets", "chevron"];

  toggle(event) {
    event.preventDefault();

    if (this.snippetsTarget.classList.contains("hidden")) {
      this.snippetsTarget.classList.remove("hidden", "fadeOut", "animated");
      this.snippetsTarget.classList.add("animated", "fadeIn");

      this.chevronTarget.classList.add("rotate-180");
    } else {
      this.snippetsTarget.classList.remove("fadeIn");
      this.snippetsTarget.classList.add("animated", "fadeOut");

      setTimeout(() => {
        this.snippetsTarget.classList.add("hidden");
      }, 300);

      this.chevronTarget.classList.remove("rotate-180");
    }
  }
}
