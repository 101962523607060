import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["taskContainer", "archivedField", "destroyField"];

  archive(event) {
    event.preventDefault();

    // Use Turbo.config.forms.confirm to show confirmation dialog
    const message = "Are you sure you want to delete this task?";
    Turbo.config.forms.confirm(message, this.element).then((confirmed) => {
      if (confirmed) {
        this.hideTaskContainer();
        this.setFieldValue();
        this.dispatchDeletionEvent();
      }
    });
  }

  hideTaskContainer() {
    this.taskContainerTarget.style.display = "none";
  }

  setFieldValue() {
    if (this.isTemporaryTask()) {
      this.destroyFieldTarget.value = "true";
    } else {
      this.archivedFieldTarget.value = "true";
    }
  }

  isTemporaryTask() {
    return this.taskContainerTarget.dataset.taskTemporary === "true";
  }

  dispatchDeletionEvent() {
    const deletionEvent = new CustomEvent("task:deleted", {
      bubbles: true,
      detail: { taskId: this.taskContainerTarget.id },
    });
    window.dispatchEvent(deletionEvent);
  }
}
